import {lazy, Suspense, useEffect, useMemo, useState} from 'react'
import {Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {MasterLayout} from '../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {PageTitle} from '../_metronic/layout/core'
import AccessRights from '../accessRights/AccessRights'
import AdminDashbaord from '../app/modules/admin/dashboard/AdminDashbaord'
import StandardDashboard from '../app/modules/standard_manager/standardDashboard/StandardDashboard'
import {useSelector} from 'react-redux'
import {getActiveRoleSelector, getUserDataSelector} from '../redux/selectors/AuthSelectors'
import {ROLES} from '../accessRights/AccessRightsConstants'
import {allPermissionsAccess} from '../accessRights/useCustomAccessRights'
import {getCompanySettingSelector} from '../redux/selectors/SettingsSelectors'
import {getRedirectRoute} from '../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuMain'
import {BREADCRUMBS} from '../customComponents/customBreadCrumb/CustomBreadCrumb'
import SequiCRMPlans from '../app/modules/standard_manager/integration/SequiCRMComponents/SequiCRMPurchasePlans'
import {AdminBillingNotice} from '../app/modules/admin/billingNotice/AdminBillingNotice'
import StanderdBillingNotice from '../app/modules/standard_manager/billingNotice/StanderdBillingNotice'
import Show from '../customComponents/show/Show'
import useIsAccountBillingDue from '../hooks/useIsAccountBillingDue'
import {getBooleanValue, lazyRetry} from '../helpers/CommonHelpers'
import TabMaintancePage from '../app/modules/admin/maintance/TabMaintancePage'

const PrivateRoutes = ({directPath, setDirectPath = () => {}}) => {
    const {isBillingOverdue} = useIsAccountBillingDue()
    const userDetails = useSelector(getUserDataSelector)
    const navigate = useNavigate()
    const Referrals = lazy(() =>
        lazyRetry(() => import('../app/modules/standard_manager/referrals/Referrals'), 'Referrals')
    )
    const IntregationPage = lazy(() =>
        lazyRetry(
            () => import('../app/modules/standard_manager/integration/IntregationPage'),
            'integration'
        )
    )
    const CalendarPage = lazy(() =>
        lazyRetry(() => import('../app/modules/standard_manager/calendar/CalendarPage'), 'Calendar')
    )
    const ReportsRoutes = lazy(() =>
        lazyRetry(
            () => import('../app/modules/standard_manager/smReports/ReportsRoutes'),
            'Reports'
        )
    )
    const SettingPage = lazy(() =>
        lazyRetry(() => import('../app/modules/admin/Setting/SettingRoutes'), 'setting')
    )
    const PayrollPage = lazy(() =>
        lazyRetry(() => import('../app/modules/admin/payroll/PayrollRoutes'), 'Payroll')
    )
    const ReportsPage = lazy(() =>
        lazyRetry(() => import('../app/modules/admin/reports/ReportsRoute'), 'Reports')
    )
    const ParticularEmployePage = lazy(() =>
        lazyRetry(
            () =>
                import(
                    '../app/modules/standard_manager/management/particularEmployee/ParticularEmployePage'
                ),
            'ParticularEmploye'
        )
    )
    const HiringPage = lazy(() =>
        lazyRetry(
            () => import('../app/modules/standard_manager/hiring/hiringRoutes/HiringRoutes'),
            'Hiring'
        )
    )
    const ManagementPage = lazy(() =>
        lazyRetry(
            () => import('../app/modules/standard_manager/management/ManagementRoutes'),
            'Management'
        )
    )
    const RequestsPage = lazy(() =>
        lazyRetry(
            () => import('../app/modules/standard_manager/smRequests/RequestsPage'),
            'smRequests'
        )
    )
    const SequiDocs = lazy(() =>
        lazyRetry(() => import('../app/modules/admin/sequidocs/SequiDocsRoutes'), 'SequiDocs')
    )
    const MarketingDealPage = lazy(() =>
        lazyRetry(() => import('../app/modules/admin/marketingDeals/MdPage'), 'MarketingDeal')
    )
    const MySalesPage = lazy(() =>
        lazyRetry(
            () =>
                import(
                    '../app/modules/standard_manager/Setting/components/SetupCard/backendCard/MySalesRoute'
                ),
            'MySales'
        )
    )

    const PersonMdPage = lazy(() =>
        lazyRetry(
            () => import('../app/modules/admin/marketingDeals/perPersonDeal/PersonMdPage'),
            'PersonMd'
        )
    )

    const PermissionsTab = lazy(() =>
        lazyRetry(() => import('../app/modules/admin/permissions/PermissionsTab'), 'Permissions')
    )
    const AlertsPage = lazy(() =>
        lazyRetry(() => import('../app/modules/admin/alert-center/AlertCenterPage'), 'Alerts')
    )

    const OnBoardEmployeProfileRoute = lazy(() =>
        lazyRetry(
            () =>
                import(
                    '../app/modules/standard_manager/onBoardEmployeProfile/OnBoardEmployeProfileRoute'
                ),
            'OnBoardEmployeProfile'
        )
    )

    const UserManageRoute = lazy(() =>
        lazyRetry(
            () => import('../app/modules/common/navSettings/userManagement/UserManagementPage'),
            'UserManage'
        )
    )

    const BillingRoute = lazy(() =>
        lazyRetry(() => import('../app/modules/common/navSettings/billing/BillingPage'), 'Billing')
    )
    const ViewLogPage = lazy(() =>
        lazyRetry(
            () => import('../app/modules/standard_manager/integration/components/ViewLogPage'),
            'ViewLog'
        )
    )
    const SmSequiDocRouting = lazy(() =>
        lazyRetry(
            () => import('../app/modules/standard_manager/smSequiDocs/SmSequiDocRouting'),
            'SmSequiDoc'
        )
    )
    const SettingSystemPage = lazy(() =>
        lazyRetry(
            () => import('../app/modules/common/navSettings/system/SettingSystemPage'),
            'SettingSystem'
        )
    )
    const UserManagementAuditLog = lazy(() =>
        lazyRetry(
            () =>
                import(
                    '../app/modules/common/navSettings/userManagement/auditLog/UserManagementAuditLog'
                ),
            'UserManagementAudit'
        )
    )
    const MainSupportPage = lazy(() =>
        lazyRetry(() => import('../app/modules/common/supportSystem/SupportRoute'), 'MainSupport')
    )
    const SclearancePurchasePlans = lazy(() =>
        lazyRetry(
            () =>
                import(
                    '../app/modules/standard_manager/integration/SclearanceComponents/SclearancePurchasePlans'
                ),
            'SclearancePurchase'
        )
    )
    const SequiAIPurchasePlans = lazy(() =>
        lazyRetry(
            () =>
                import(
                    '../app/modules/standard_manager/integration/sequiAIComponents/SequiAIPurchasePlans'
                ),
            'SequiAIPurchase'
        )
    )

    const SclearanceRegistrationForm = lazy(() =>
        lazyRetry(
            () =>
                import(
                    '../app/modules/standard_manager/integration/SclearanceComponents/SclearanceRegistrationForm'
                ),
            'SclearanceRegistration'
        )
    )
    const SClearanceRoutes = lazy(() =>
        lazyRetry(() => import('../app/modules/admin/s-Clearance/SClearanceRoutes'), 'SClearance')
    )

    const SequiCRMPage = lazy(() =>
        lazyRetry(() => import('../app/modules/admin/sequiCRM/sequiCRMRoutes'), 'SequiCRM')
    )
    const LeaderboardPage = lazy(() =>
        lazyRetry(
            () =>
                import(
                    '../app/modules/standard_manager/standardDashboard/leaderBoard/LeaderboardPage'
                ),
            'Leaderboard'
        )
    )
    const UiStyleSheet = lazy(() =>
        lazyRetry(() => import('../app/modules/developersFolder/UiStyleSheet'), 'UiStyleSheet')
    )

    const profileBreadCrumbs = [
        {
            title: 'Dashboard/',
            path: '/',
            isSeparator: false,
            isActive: false,
        },
    ]
    useEffect(() => {
        if (userDetails?.isClockIn && getBooleanValue(userDetails?.wages_status) == 1)
            navigate('/mysales/my-wages')
    }, [])
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                <Route path='auth/*' element={<Navigate to={'/dashboard'} />} />
                <Route
                    path='/dashboard'
                    element={
                        <SuspensedView>
                            <Dashboard directPath={directPath} setDirectPath={setDirectPath} />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/standard-dashboard'
                    element={
                        <SuspensedView>
                            <Show>
                                <Show.When isVisible={isBillingOverdue}>
                                    <StanderdBillingNotice />
                                </Show.When>
                                <Show.Else>
                                    <AccessRights
                                        customCondition={
                                            allPermissionsAccess.standard.dashboard.dashboard.view
                                        }
                                        showPlaceHolder
                                    >
                                        <StandardDashboard />
                                    </AccessRights>
                                </Show.Else>
                            </Show>
                        </SuspensedView>
                    }
                />

                <Route
                    path='/admin-dashboard'
                    element={
                        <SuspensedView>
                            <Show>
                                <Show.When isVisible={isBillingOverdue}>
                                    <AdminBillingNotice />
                                </Show.When>
                                <Show.Else>
                                    <AccessRights
                                        customCondition={
                                            allPermissionsAccess.administrator.dashboard.dashboard
                                                .view
                                        }
                                        showPlaceHolder
                                    >
                                        <AdminDashbaord />
                                    </AccessRights>
                                </Show.Else>
                            </Show>
                        </SuspensedView>
                    }
                />

                <Route
                    path='standard-dashboard/leaderboards'
                    element={
                        <SuspensedView>
                            <AccessRights
                                customCondition={
                                    allPermissionsAccess.standard.dashboard.dashboard.view
                                }
                                showPlaceHolder
                            >
                                <LeaderboardPage />
                            </AccessRights>
                        </SuspensedView>
                    }
                />

                <Route
                    path='user/*'
                    element={
                        <SuspensedView>
                            <ParticularEmployePage />
                        </SuspensedView>
                    }
                />

                <Route
                    path='settings/user-management'
                    element={
                        <SuspensedView>
                            <UserManageRoute />
                        </SuspensedView>
                    }
                />
                <Route
                    path='settings/user-management/audit-log'
                    element={
                        <SuspensedView>
                            <PageTitle
                                breadcrumbs={BREADCRUMBS.NAVBAR_SETTINGS.USER_MANAGEMENT_AUDIT_LOG}
                            >
                                Audit log
                            </PageTitle>

                            <UserManagementAuditLog />
                        </SuspensedView>
                    }
                />
                <Route
                    path='settings/billings/*'
                    element={
                        <SuspensedView>
                            <BillingRoute />
                        </SuspensedView>
                    }
                />

                <Route
                    path='settings/system/*'
                    element={
                        <SuspensedView>
                            <SettingSystemPage />
                        </SuspensedView>
                    }
                />

                {/* Support System */}
                <Route
                    path='support/*'
                    element={
                        <SuspensedView>
                            <MainSupportPage />
                        </SuspensedView>
                    }
                />

                {/* Developers route */}
                <Route
                    path='style-sheet'
                    element={
                        <SuspensedView>
                            <UiStyleSheet />
                        </SuspensedView>
                    }
                />
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />

                {!isBillingOverdue ? (
                    <>
                        <Route
                            path='integration'
                            element={
                                <SuspensedView>
                                    <IntregationPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='integration/purchase-plan/*'
                            element={
                                <Routes>
                                    <Route
                                        path='/crm'
                                        element={
                                            <SuspensedView>
                                                <SequiCRMPlans />
                                            </SuspensedView>
                                        }
                                    />
                                    <Route
                                        path='/sclearance'
                                        element={
                                            <SuspensedView>
                                                <SclearancePurchasePlans />
                                            </SuspensedView>
                                        }
                                    />
                                    <Route
                                        path='/sclearance/registration'
                                        element={
                                            <SuspensedView>
                                                <SclearanceRegistrationForm />
                                            </SuspensedView>
                                        }
                                    />
                                    <Route
                                        path='/sequiai'
                                        element={
                                            <SuspensedView>
                                                <SequiAIPurchasePlans />
                                            </SuspensedView>
                                        }
                                    />
                                </Routes>
                            }
                        />
                        <Route
                            path='integration/view-log'
                            element={
                                <SuspensedView>
                                    <ViewLogPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='import-export'
                            element={
                                <SuspensedView>
                                    <IntregationPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='calendar'
                            element={
                                <SuspensedView>
                                    <AccessRights
                                        customCondition={
                                            allPermissionsAccess.standard.calendar.calendar.view
                                        }
                                        showPlaceHolder
                                    >
                                        <CalendarPage />
                                    </AccessRights>
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='referrals'
                            element={
                                <SuspensedView>
                                    <PageTitle breadcrumbs={profileBreadCrumbs}>
                                        Referrals
                                    </PageTitle>
                                    <AccessRights
                                        customCondition={
                                            allPermissionsAccess.standard.referrals.referrals.view
                                        }
                                        showPlaceHolder
                                    >
                                        <Referrals />
                                    </AccessRights>
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='permissions/*'
                            element={
                                <SuspensedView>
                                    <PermissionsTab />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='sequicrm/*'
                            element={
                                <SuspensedView>
                                    <SequiCRMPage />
                                </SuspensedView>
                            }
                        />{' '}
                        <Route
                            path='marketing-deal/*'
                            element={
                                <SuspensedView>
                                    <MarketingDealPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='marketing-deal/md-list/md-per-person/*'
                            element={
                                <SuspensedView>
                                    <PersonMdPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='management/*'
                            element={
                                <SuspensedView>
                                    <ManagementPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='hiring/*'
                            element={
                                <SuspensedView>
                                    <HiringPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='mysales/*'
                            element={
                                <SuspensedView>
                                    <MySalesPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='payroll/*'
                            element={
                                <SuspensedView>
                                    <PayrollPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='admin/*'
                            element={
                                <Routes>
                                    <Route
                                        path='reports/*'
                                        element={
                                            <SuspensedView>
                                                <ReportsPage />
                                            </SuspensedView>
                                        }
                                    />
                                    <Route
                                        path='sequidocs/*'
                                        element={
                                            <SuspensedView>
                                                <SequiDocs />
                                                {/* <TabMaintancePage tabName={'Sequidocs'} /> */}
                                            </SuspensedView>
                                        }
                                    />
                                </Routes>
                            }
                        />
                        <Route
                            path='requests/*'
                            element={
                                <SuspensedView>
                                    <RequestsPage />
                                </SuspensedView>
                            }
                        />
                        {/* Sm reports */}
                        <Route
                            path='standard/*'
                            element={
                                <Routes>
                                    <Route
                                        path='/sequidocs/*'
                                        element={
                                            <SuspensedView>
                                                <SmSequiDocRouting />
                                                {/* <TabMaintancePage tabName={'Sequidocs'} /> */}
                                            </SuspensedView>
                                        }
                                    />
                                    <Route
                                        path='reports/*'
                                        element={
                                            <SuspensedView>
                                                <ReportsRoutes />
                                            </SuspensedView>
                                        }
                                    />
                                </Routes>
                            }
                        />
                        {/* Sm onBoarding Employe Prifle */}
                        <Route
                            path='onboard-employe-profile/*'
                            element={
                                <SuspensedView>
                                    <OnBoardEmployeProfileRoute />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='alert-center/*'
                            element={
                                <SuspensedView>
                                    <AlertsPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='s-clearance/*'
                            element={
                                <SuspensedView>
                                    <SClearanceRoutes />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='setting/*'
                            element={
                                <SuspensedView>
                                    <SettingPage />
                                </SuspensedView>
                            }
                        />
                    </>
                ) : (
                    <></>
                )}
            </Route>
        </Routes>
    )
}

export const SuspensedView = ({children}) => {
    TopBarProgress.config({
        barColors: {
            0: '#6078ec',
        },
        barThickness: 3,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

const Dashboard = ({directPath, setDirectPath = () => {}}) => {
    const activeRole = useSelector(getActiveRoleSelector)
    const [showPlaceholder, setPlaceHolder] = useState(false)
    const navigate = useNavigate()
    const companySetting = useSelector(getCompanySettingSelector)
    const redirectRoute = useMemo(() => {
        return getRedirectRoute({companySetting})
    }, [companySetting])

    const adminFirstScreen = useMemo(() => {
        const adminRoute = redirectRoute.administrator

        return (
            adminRoute?.dashboard ||
            adminRoute?.settings ||
            adminRoute?.integrations ||
            adminRoute?.sequiDocs ||
            adminRoute?.payroll ||
            adminRoute?.sClearance ||
            adminRoute?.reports ||
            adminRoute?.permissions ||
            adminRoute?.alertCenter ||
            null
        )
    }, [redirectRoute.administrator])

    const standardFirstScreen = useMemo(() => {
        const standardRoute = redirectRoute.standard
        return (
            standardRoute?.dashboard ||
            standardRoute?.mySales ||
            standardRoute?.hiring ||
            standardRoute?.calendar ||
            standardRoute?.sequiDocs ||
            standardRoute?.management ||
            standardRoute?.reports ||
            standardRoute?.requestAndApprovals ||
            null
        )
    }, [redirectRoute.standard])

    useEffect(() => {
        if (directPath) {
            navigate(directPath)
            setDirectPath(null)
        } else {
            const pathName =
                activeRole === ROLES.standard.roleValue ? standardFirstScreen : adminFirstScreen
            if (pathName) navigate(pathName)
            setTimeout(() => setPlaceHolder(true), 1500)
        }
    }, [])

    return <AccessRights showPlaceHolder={showPlaceholder} customCondition={false} />
}
